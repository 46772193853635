// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::selection {
    background: #00bfbf;
    color: #fafafa;
    text-shadow: none;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
html {
    overflow: scroll;
    overflow-x: hidden;
    font-size: 16px;
}
body {
    transition: all 0.5s ease;
}
p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAKA;IACI,mBAAmB;IACnB,cAAc;IACd,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,uBAAuB;AAC3B;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,uBAAuB;AAC3B","sourcesContent":["::-moz-selection {\n    background: #00bfbf;\n    color: #fafafa;\n    text-shadow: none;\n}\n::selection {\n    background: #00bfbf;\n    color: #fafafa;\n    text-shadow: none;\n}\n::-webkit-scrollbar {\n    width: 0px;\n    background: transparent;\n}\nhtml {\n    overflow: scroll;\n    overflow-x: hidden;\n    font-size: 16px;\n}\nbody {\n    transition: all 0.5s ease;\n}\np {\n    margin-block-start: 0.5em;\n    margin-block-end: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
