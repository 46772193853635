// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.displacement-sphere {
    position: fixed;
    width: 100vw;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    transition-property: opacity;
    transition-duration: 3s;
    transition-timing-function: "cubic-bezier(0.4, 0.0, 0.2, 1)";
}

.displacement-sphere--entering,
.displacement-sphere--entered {
    opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/background/DisplacementSphere.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,MAAM;IACN,QAAQ;IACR,SAAS;IACT,OAAO;IACP,UAAU;IACV,WAAW;IACX,4BAA4B;IAC5B,uBAAuB;IACvB,4DAA4D;AAChE;;AAEA;;IAEI,UAAU;AACd","sourcesContent":[".displacement-sphere {\n    position: fixed;\n    width: 100vw;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    opacity: 0;\n    z-index: -1;\n    transition-property: opacity;\n    transition-duration: 3s;\n    transition-timing-function: \"cubic-bezier(0.4, 0.0, 0.2, 1)\";\n}\n\n.displacement-sphere--entering,\n.displacement-sphere--entered {\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
